import { Flex, Heading, HeadingProps, Text } from "@chakra-ui/react";

export type MyProfileSectionProps = {
	title?: string | null;
	description?: string;
	children: JSX.Element | JSX.Element[];
	headingProps?: HeadingProps;
	noPadding?: boolean;
	appendItem?: JSX.Element;
	prependItem?: JSX.Element;
};

export const MyProfileSection = ({
    title,
    appendItem,
    prependItem,
    description,
    children,
    headingProps,
    noPadding,
}: MyProfileSectionProps) => {
    const internalHeadingProps: HeadingProps = headingProps
        ? headingProps
        : { as: "h1", paddingTop: "1rem", paddingBottom: "1rem" };

    return (
        <>
            <Flex direction="column" paddingTop={!noPadding ? "1rem" : undefined}>
                <Flex
                    justify={"space-between"}
                    alignItems={"center"}
                    paddingTop={!noPadding ? "1rem" : undefined}
                >
                    {title || prependItem ? (
                        <Flex gap={"0.5rem"}>
                            {prependItem ? prependItem : null}
                            {<Heading {...internalHeadingProps}>{title}</Heading>}
                        </Flex>
                    ) : null}
                    {appendItem ? appendItem : null}
                </Flex>
                {description ? (
                    <Text color={"lightgray"} paddingBottom={"1rem"}>
                        {description}
                    </Text>
                ) : null}
            </Flex>
            {children}
        </>
    );
};
