import { Container, Row, Spinner } from 'react-bootstrap';
import { FlexComponent } from '../components/flex.component';
import { ContainerFullWidthHeightComponent } from "../components/rows/container-full-width-height.component";
import "./../screens/css/global-screens.css";

import { globalStyles } from '../colors/global-styles';

import { useState } from 'react';
import { BiLogOut } from 'react-icons/bi';

import { Navigate } from "react-router-dom";
import { isUser } from '../common/object.utilities';
import { AvatarMapperComponent } from './avatar/avatar.component';

import { Box, Stack, Text } from '@chakra-ui/react';
import { IoNotificationsSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { formatDate, isOlderThan4Hours } from '../common/date.utilities';
import NavBar from '../components/menu/menu';
import { Lang } from '../lang/lang';
import { Conversation } from '../ov-common/conversation';
import { User, UserListItem } from '../ov-common/models/user';
import { mapUserToUserListItem } from '../ov-common/utilities/map.utilities';
import { useLogout } from './auth/hook/auth-query';
import { useGetRecentConversations } from './home-screen.query';

export const HomeScreenComponent = () => {
    const { t, lang } = Lang(); // Access the translation function
    const { data: conversations, isLoading } = useGetRecentConversations(50);

    const [ userClickedOn, setUserClickedOn ] = useState<UserListItem | null>(null);

    const handleLogout = () => {
        useLogout();
    };

    function sendLetterNavigate(conversation: Conversation) {
        return () => {
            if (isUser(conversation.anvandareID1)) {
                return setUserClickedOn(mapUserToUserListItem(conversation.anvandareID1 as User));
            } else if (isUser(conversation.anvandareID2)) {
                return setUserClickedOn(mapUserToUserListItem(conversation.anvandareID2 as User));
            }
        };
    }

    return (
        <ContainerFullWidthHeightComponent>
            {userClickedOn && <Navigate to='/send-letter' state={{ user: userClickedOn, from: 'inbox' }} />}
            <NavBar />
            <Container style={{ maxWidth: "360px", overflow: "hidden" }}>
                <Row>
                    <div>
                        <Container>
                            <Box
                                cursor="pointer"
                                padding={"1rem"}
                                paddingTop={"1.5rem"}
                                borderColor={"lightgray"}>
                                {conversations != null ? (
                                    <Stack direction={"row"} gap={2}>
                                        {hasUnreadMessages(conversations) ? (
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10 }}>
                                                <IoNotificationsSharp
                                                    color={hasUnreadMessages(conversations) ? globalStyles.greenColor.color : globalStyles.grayColor.color}
                                                    size={30}
                                                />
                                                <p style={{ margin: 0 }}>
                                                    {t("homeScreenUnreadMessages") + " (" + numberOfUnreadMessages(conversations) + ")"}
                                                </p>
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10 }}>
                                                <img src="/postman-min.png" alt="No new messages" style={{ width: 50, marginRight: 10, marginTop: -10 }} />
                                                <p style={{ margin: 0 }}>{t("homeScreenNoNewMessages")}</p>
                                            </div>
                                        )}
                                    </Stack>
                                ) : isLoading ? <Spinner /> : null}
                            </Box>
                        </Container>
                    </div>
                </Row>
            </Container>
            <Container style={{ maxWidth: "360px", overflow: "hidden" }}>
                <Row>
                    <div>
                        <Container>
                            {conversations && Array.isArray(conversations) && conversations.length > 0 && conversations.map((conversation, index) => {
                                const letterOnTheWay = !isOlderThan4Hours(conversation.senastAktiv);
                                const postman = '/postman-min.png';
                                const postmanImg = new Image();
                                postmanImg.src = postman;
                                const isUnread = conversation?.last && conversation.last.length > 0;
                                return (
                                    <Box
                                        key={index}
                                        onClick={() => sendLetterNavigate(conversation)()}
                                        cursor="pointer"
                                        padding={"1rem"}
                                        borderColor={"lightgray"}>
                                        <Stack direction={"row"} gap={2}>
                                            {letterOnTheWay && getPostman(false, t)}
                                            {!letterOnTheWay  && <Box mt={-10}>
                                                {!letterOnTheWay && isUser(conversation.anvandareID1) && conversation?.anvandareID1?.avatar && <FlexComponent>
                                                    <AvatarMapperComponent
                                                        width={80}
                                                        avatar={conversation?.anvandareID1?.avatar}
                                                    />
                                                </FlexComponent>}
                                                {!letterOnTheWay && isUser(conversation.anvandareID2) && conversation?.anvandareID2?.avatar && <FlexComponent>
                                                    <AvatarMapperComponent
                                                        width={80}
                                                        avatar={conversation?.anvandareID2?.avatar}
                                                    />
                                                </FlexComponent>}
                                            </Box>}
                                            <Box flex={"auto"}>
                                                <Stack direction={"row"}>
                                                    <Box width={"100%"}>
                                                        <Text
                                                            m={0}
                                                            textAlign={"left"}
                                                            fontSize={"medium"}
                                                            noOfLines={1}
                                                            fontWeight={"400"}>
                                                            {isUser(conversation.anvandareID1) && conversation?.anvandareID1?.avatar ? conversation.anvandareID1.anvandarnamn.substring(0, 15)
                                                                : isUser(conversation.anvandareID2) && conversation?.anvandareID2?.avatar ? conversation.anvandareID2.anvandarnamn.substring(0, 15) : ''}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Text m={0} noOfLines={1} isTruncated={true} color={"#A8A8A8"} fontWeight={"400"} fontSize={"1rem"}>
                                                            {formatDate(conversation.datum, lang)}
                                                        </Text>
                                                    </Box>
                                                </Stack>
                                                <Stack direction={"row"}>
                                                    <Box flex={1}>
                                                        <Text
                                                            m={0}
                                                            gap={2}
                                                            color={
                                                                isUnread
                                                                    ? 'black'
                                                                    : "lightgray"
                                                            }
                                                            fontSize={"16px"}
                                                            fontWeight={isUnread ? "700" : "normal"}>
                                                            {conversation.latestLetterMessage?.substring(0, 50)}
                                                        </Text>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Box>
                                );
                            })}

                        </Container>
                    </div>
                </Row>
            </Container>
            <div style={{ float: 'left', margin: 10, position: 'sticky', bottom: 0 }}>
                <span onClick={handleLogout} style={{ cursor: 'pointer' }}>
                    <BiLogOut
                        size={20}
                        color={'black'}
                    />
                    <Text m={0} mb={1} fontSize={"0.75rem"}>{t("homeScreenLogout")}</Text>
                </span>
            </div>
        </ContainerFullWidthHeightComponent>
    );
};

export function getPostman(fromMe = false, t: (key: string) => string) {
    return (
        <a onClick={() => {
            toast(t("homeScreenPostmanDelivery"));
        }}>
            <img id="postman" src="/postman-min.png" alt="Image Description" width={100} style={{ margin: "0 auto" }} />
            <p className='snellbt' style={{ fontSize: "0.95rem" }}>{fromMe ? t("homeScreenPostmanFromMe") : t("homeScreenPostmanFromOther")}</p>
        </a>
    );
}

export function hasUnreadMessages(conversations: Conversation[]) {
    return conversations && Array.isArray(conversations) && conversations.length > 0 && numberOfUnreadMessages(conversations) > 0;
}

export function numberOfUnreadMessages(conversations: Conversation[]) {
    return conversations.filter(x => x?.last != null && x.last.length > 0).length;
}
