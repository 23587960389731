import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillProfile } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';
import { Navigate } from 'react-router-dom';
import { Lang } from '../../lang/lang';

const NavBar = () => {
    const { t } = Lang();
    const [ clickedOnMyCards, setClickedOnMyCards ] = useState(false);
    const [ clickedOnGrade, setClickedOnGrade ] = useState(false);
    return (
        <Box
            bg="#F6F5F4"
            shadow="lg"
            m={0}
            borderTop="1px solid #E2E8F0"
        >
            {clickedOnMyCards && <Navigate to='/settings' />}
            {clickedOnGrade && <Navigate to='/grade' />}
            <HStack
                width="100%"
                align="center"
                spacing={0}
                m={0}
            >
                <VStack
                    width="50%"
                    justify="center"
                    align="center"
                    spacing={0}
                    padding={0}
                    _hover={{ backgroundColor: "#DCE7E8" }}
                    margin={0}
                    p={2}
                    onClick={() => setClickedOnMyCards(true)}
                    flex="1"
                >
                    <AiFillProfile />
                    <Text m={0} fontSize={"0.75rem"}>{t("navBarSettings")}</Text>
                </VStack>

                <VStack
                    width="50%"
                    justify="center"
                    align="center"
                    spacing={0}
                    padding={0}
                    _hover={{ backgroundColor: "#DCE7E8" }}
                    margin={0}
                    onClick={() => setClickedOnGrade(true)}
                    p={2}
                    flex="1"
                >
                    <BiPencil />
                    <Text m={0} fontSize={"0.75rem"}>{t("navBarGrade")}</Text>
                </VStack>
            </HStack>
        </Box>
    );
};

export default NavBar;
