import {
    Modal as ChakraModal,
    ModalProps as ChakraModalProps,
    ModalContent,
    ModalOverlay,
} from '@chakra-ui/react';

type CustomModalProperties = {
  /** Defines max width of modal on larger viewports. With the exception of 'full'
   * which covers 100% of any screen both horizontally and vertically. */
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
};

type CharkaModalProperties = Pick<
  ChakraModalProps,
  | 'children'
  | 'onClose'
  | 'isOpen'
  | 'id'
  | 'isCentered'
  | 'scrollBehavior'
  | 'autoFocus'
  | 'returnFocusOnClose'
  | 'onCloseComplete'
  | 'closeOnOverlayClick'
  | 'closeOnEsc'
>;

export type ModalProps = CustomModalProperties & CharkaModalProperties;

export function Modal({
    children,
    size = 'sm',
    scrollBehavior = 'inside',
    autoFocus = false,
    isCentered = true,
    ...props
}: ModalProps) {
    return (
        <ChakraModal
            // Note: Usage of variant is only internal,
            // should always trigger "bottom sheet"-variant on smaller screens
            variant={{ base: 'bottomSheet', md: 'default' }}
            scrollBehavior={scrollBehavior}
            motionPreset="slideInBottom"
            autoFocus={autoFocus}
            size={size}
            isCentered={isCentered}
            {...props}
        >
            <ModalOverlay />
            <ModalContent>{children}</ModalContent>
        </ChakraModal>
    );
}
