import { User } from "../ov-common/models/user";

export function isUser<T>(obj: T | User): obj is User {
    return (obj as User)?.anvandarnamn !== undefined;
}

export function cloneDeep<T>(obj: T | null): T  {
    const n = JSON.parse(JSON.stringify(obj)) as T;
    if(Array.isArray(n)) {
        return n;
    }
    // check if object
    if(typeof n === 'object') {
        return { ...n };
    }
    return n;
}