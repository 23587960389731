
import './css/speech-bubble.component.css';

export class SpeechBubble {
    public children?: React.ReactNode;
}

export const SpeechBubbleComponent = (props: SpeechBubble) => {
    return (
        <div className="bubble bubble-bottom-left">
            {props.children}
        </div>
    );
};