import { Heading, Stack, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { FlexComponent } from '../../components/flex.component';
import { ProgressComponent, Steps } from "../../components/progress-bar.component";
import { Lang } from "../../lang/lang";
import { getUser } from "../auth/hook/auth-query";
import { GoBackButton } from "../letters/send-letters.screen";
import { useUpdateLanguage } from "./hooks/user-query";

// Mapping countries to their respective languages
export const languages = {
    "Pashto": "Pashto",
    "Dari": "Dari",
    "Albanian": "Albanian",
    "Arabic": "Arabic",
    "English": "English",
    "Samoan": "Samoan",
    "Catalan": "Catalan",
    "Portuguese": "Portuguese",
    "Spanish": "Spanish",
    "Armenian": "Armenian",
    "Dutch": "Dutch",
    "Papiamento": "Papiamento",
    "German": "German",
    "Azerbaijani": "Azerbaijani",
    "Bengali": "Bengali",
    "Belarusian": "Belarusian",
    "Russian": "Russian",
    "French": "French",
    "Dzongkha": "Dzongkha",
    "Quechua": "Quechua",
    "Aymara": "Aymara",
    "Bosnian": "Bosnian",
    "Croatian": "Croatian",
    "Serbian": "Serbian",
    "Setswana": "Setswana",
    "Khmer": "Khmer",
    "Sango": "Sango",
    "Comorian": "Comorian",
    "Maori": "Maori",
    "Greek": "Greek",
    "Turkish": "Turkish",
    "Czech": "Czech",
    "Estonian": "Estonian",
    "Amharic": "Amharic",
    "Faroese": "Faroese",
    "Fijian": "Fijian",
    "Finnish": "Finnish",
    "Swedish": "Swedish",
    "Tigrinya": "Tigrinya",
    "Georgian": "Georgian",
    "Swahili": "Swahili",
    "Gilbertese": "Gilbertese",
    "Korean": "Korean",
    "Kazakh": "Kazakh",
    "Kyrgyz": "Kyrgyz",
    "Lao": "Lao",
    "Latvian": "Latvian",
    "Sesotho": "Sesotho",
    "Luxembourgish": "Luxembourgish",
    "Macedonian": "Macedonian",
    "Malagasy": "Malagasy",
    "Chichewa": "Chichewa",
    "Malay": "Malay",
    "Dhivehi": "Dhivehi",
    "Maltese": "Maltese",
    "Marshallese": "Marshallese",
    "Mongolian": "Mongolian",
    "Montenegrin": "Montenegrin",
    "Burmese": "Burmese",
    "Nauruan": "Nauruan",
    "Nepali": "Nepali",
    "Polish": "Polish",
    "Romanian": "Romanian",
    "Kinyarwanda": "Kinyarwanda",
    "Seychellois Creole": "Seychellois Creole",
    "Somali": "Somali",
    "Zulu": "Zulu",
    "Xhosa": "Xhosa",
    "Afrikaans": "Afrikaans",
    "Sinhala": "Sinhala",
    "Tamil": "Tamil",
    "Thai": "Thai",
    "Tajik": "Tajik",
    "Tok Pisin": "Tok Pisin",
    "Hiri Motu": "Hiri Motu",
    "Guarani": "Guarani",
    "Filipino": "Filipino",
    "Vietnamese": "Vietnamese",
    "Bislama": "Bislama",
    "Tuvaluan": "Tuvaluan",
    "Uzbek": "Uzbek",
};

export const LanguageScreen = () => {
    const [ selected, setSelected ] = useState<any>(null);
    const [ clickedBack, setClickedBack ] = useState(false);
    const location = useLocation();
    const { mutate: updateLanguage } = useUpdateLanguage();
    const user = getUser();
    const [ hasUpdatedOnce, setHasUpdatedOnce ] = useState(false);
    const { t } = Lang();  // Get the translation function

    useEffect(() => {
        if (user) {
            setSelected({
                label: user.langIndex,
                value: user.langIndex,
            });
        }
    }, [ user ]);

    const steps = new Steps(5, 3);

    return (
        <>
            {hasUpdatedOnce && location.state?.from === "signup" ? (
                <Navigate to="/goals" state={{ from: 'signup', avatar: location.state?.avatar }} />
            ) : null}

            {location.state?.from !== "signup" && GoBackButton(clickedBack, location, setClickedBack, t)}

            <Stack justifyContent={"center"} alignItems={"center"}>
                {location.state?.from === "signup" && <ProgressComponent steps={steps.steps} atStep={steps.atStep} />}
                <FlexComponent>
                    <Stack spacing={6} align="center" width="100%" p={10}>
                        <Text fontSize="lg" fontWeight="medium">
                            {t("languageScreenCurrentWritingLanguage") + " " + (user?.langIndex === "Swedish" ? "Svenska" : user?.langIndex)}
                        </Text>
                        <Heading as="h1" size="lg">
                            {t("languageScreenSelectLanguagePrompt")}
                        </Heading>

                        <Select
                            value={selected}
                            placeholder={t("languageScreenSearchPlaceholder")}
                            options={Object.entries(languages).map(([ code, language ]) => ({
                                label: language,
                                value: code,
                            }))}
                            onChange={(selectedOptions: any) => {
                                setSelected(selectedOptions);
                                updateLanguage(selectedOptions.label);
                                setHasUpdatedOnce(true);
                            }}
                        />
                    </Stack>
                </FlexComponent>
            </Stack>
        </>
    );
};