import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../query-keys';
import { getLatestConversation } from '../services/letters.service';

export const useGetRecentConversations = (limit: number) => {
    return useQuery({
        queryKey: [ QueryKeys.RecentConversation, limit ],
        queryFn: () => getLatestConversation(limit),
        refetchInterval: 30000,
    });
};
