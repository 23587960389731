
import { darkMode } from '../App';
import { GetBoxColor } from '../colors/global-styles';
import './css/colored-box.component.css';

export const ColoredBoxComponent = (props: any) => {
    // create a div with some margins
    return (
        <div className='border-container'>
            <div className='card-inside'>
                {props.children}
            </div>
        </div>
    );
};

export const ColoredBorderBottomTopComponent = (props: any) => {
    // create a div with some margins
    return (
        <div className='border-container-bottom-top'>
            <div className='card-inside' style={{ paddingLeft: 10, paddingRight: 10 }}>
                {props.children}
            </div>
        </div>
    );
};

export const ColoredBorderTopComponent = (props: any) => {
    // create a div with some margins
    return (
        <div className='border-container-top'>
            <div className='card-inside'>
                {props.children}
            </div>
        </div>
    );
};

export const ColoredBorderBottomComponent = (props: any) => {
    // create a div with some margins
    let bg = 'white';
    if (darkMode) {
        bg = '#2A2B32';
    }
    return (
        <div className={props.gray ? 'border-container-bottom-gray' : props.black ? 'border-container-bottom-black' : 'border-container-bottom'}>
            <div style={{ background: GetBoxColor(darkMode), width: '100%', height: 20, marginTop: -4 }}></div>
            <div style={{ background: bg, textAlign: 'left' }}>
                {props.children}
            </div>
        </div>
    );
};