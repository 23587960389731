import { Button, Heading, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { inputStyle } from '../../colors/global-styles';
import { FlexComponent } from '../../components/flex.component';
import { Lang } from '../../lang/lang'; // Import Lang for translation
import { getUser } from '../auth/hook/auth-query';
import { MAX_AGE } from '../auth/signup.screen';
import { GoBackButton } from '../letters/send-letters.screen';
import { useUpdateBirthday } from './hooks/user-query';

export const AgeScreen = () => {
    const [ birthday, setBirthday ] = useState<string>("");
    const { mutate: updateAge } = useUpdateBirthday();
    const user = getUser();
    const [ clickedGoBack, setClickedGoBack ] = useState(false);
    const location = useLocation();
    const { t } = Lang();

    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - MAX_AGE);
    maxDate.setMonth(11);
    maxDate.setDate(31);
    const maxDateStr = maxDate.toISOString().substr(0, 10);

    useEffect(() => {
        if (user) {
            setBirthday(new Date(user?.birthday ?? "2000-01-01")?.toISOString().substr(0, 10) || "");
        }
    }, [ user ]);

    const handleDateSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (new Date(value).getTime() > new Date(maxDateStr).getTime()) {
            setBirthday(maxDateStr);
        } else {
            setBirthday(value);
        }
    };

    return (
        <>
            {GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            <Stack justifyContent={"center"} alignItems={"center"}>
                <FlexComponent>
                    <Stack spacing={6} align="center" width="100%" p={10}>
                        <Heading as="h2" size="md">
                            {t("ageScreenEnterBirthday")}
                        </Heading>
                        <input
                            type="date"
                            name="dateOfBirth"
                            id="dateOfBirth"
                            style={inputStyle.input}
                            value={birthday}
                            onChange={handleDateSelect}
                        />
                        <Button
                            colorScheme="teal"
                            onClick={() => {
                                if (birthday) {
                                    updateAge(birthday);
                                }
                            }}
                        >
                            {t("ageScreenUpdateAgeButton")}
                        </Button>
                    </Stack>
                </FlexComponent>
            </Stack>
        </>
    );
};
