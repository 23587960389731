import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { User } from "../../../ov-common/models/user";
import { QueryKeys } from "../../../query-keys";
import { engageWithUser, updateAvatar, updateBirthday, updateGoal, updateLangIndex, updatePersonalInformation } from "../../../services/users.sevice";
import { Avatar } from "../../avatar/avatar.component";

export const useUpdateAvatar = () => {
    return useMutation({
        mutationFn: (newAvatar: Avatar) => updateAvatar(newAvatar),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.User ], (oldData: User) => {
                return {
                    ...oldData,
                    avatar: data,
                };
            
            });
        },
    });
};

export const useUpdateLanguage = () => {
    return useMutation({
        mutationFn: (language: string) => updateLangIndex(language),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.User ], (oldData: User) => {
                return {
                    ...oldData,
                    langIndex: data,
                };
            }
            );
        },
    });
};

export const useUpdateBirthday = () => {
    return useMutation({
        mutationFn: (birthday: string) => updateBirthday(birthday),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.User ], (oldData: User) => {
                return {
                    ...oldData,
                    birthday: data,
                };
            }
            );
        },
    });
};

export interface updatePersonalInformationProps {
    anvandarnamn: string;
    losenord: string;
}

export const useUpdatePasswordAndUsername = () => {
    return useMutation({
        mutationFn: (user: updatePersonalInformationProps) => updatePersonalInformation(user),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.User ], (oldData: User) => {
                return {
                    ...oldData,
                    anvandarnamn: data.anvandarnamn,
                };
            }
            );
        },
    });
};

export const useEngageWithUser = () => {
    return useMutation({
        mutationFn: (userId: string) => engageWithUser(userId),
    });
};

interface updateGoalProps {
    goal: string;
    userId: string;
}
export const useUpdateGoal = () => {
    return useMutation({
        mutationFn: (prop: updateGoalProps) => updateGoal(prop.goal, prop.userId),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.User ], () => {
                return data;
            }
            );
        },
    });
};