import { Box, Button, FormControl, HStack, IconButton, Textarea } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Navigate, useLocation } from 'react-router-dom';
import { fruitColors, margins } from '../../colors/global-styles';
import { FlexComponent } from '../../components/flex.component';
import { ProgressComponent, Steps } from '../../components/progress-bar.component';
import { ContainerFullWidthHeightComponent } from '../../components/rows/container-full-width-height.component';
import { SpeechBubbleComponent } from '../../components/speech-bubble.component';
import { Lang } from '../../lang/lang';
import { CardBio } from '../../ov-common/models/card';
import { getUser } from '../auth/hook/auth-query';
import { Avatar, AvatarMapperComponent } from '../avatar/avatar.component';
import { GoBackButton } from '../letters/send-letters.screen';
import { useAddCard, useGetCard, useRemoveCard } from './hooks/card-query';
import { useEngageWithUser } from './hooks/user-query';

export const WriteBioComponent = () => {
    const { t } = Lang();
    const steps = new Steps(5, 5);
    const location = useLocation();
    const [ avatar, setAvatar ] = useState<Avatar | null>(location?.state?.avatar);
    const user = getUser();
    const { data: cards } = useGetCard(user?._id);
    const [ clickedGoBack, setClickedGoBack ] = useState<boolean>(false);
    const { mutateAsync: removeCardAsync } = useRemoveCard();
    const {  mutateAsync: addCardAsync } = useAddCard();
    const { mutate: engageWithUser } = useEngageWithUser();
    const [ navigate, setNavigate ] = useState<boolean>(false);
    const [ allNewAndOldCards, setAllNewAndOldCards ] = useState<CardBio[]>([]);
    const [ originalCards, setOriginalCards ] = useState<CardBio[]>([]);

    useEffect(() => {
        if (cards && cards?.length > 0) {
            setAllNewAndOldCards(cards);
            setOriginalCards(JSON.parse(JSON.stringify(cards)));
        }
    }, [ cards ]);

    const addNewCard = () => {
        const card = new CardBio(null, "", "", new Date(), user._id);
        setAllNewAndOldCards([ ...allNewAndOldCards, card ]);
    };

    useEffect(() => {
        if (user && typeof user.avatar !== 'string') {
            setAvatar(user.avatar);
        }
    }, [ user ]);

    async function handleCard(formData: FormData | null) {
        for(let i = 0; i < allNewAndOldCards.length; i++) {
            const originalCard = originalCards?.find((card) => card.id === allNewAndOldCards[i].id);
            if(originalCard){
                if(originalCard.text !== allNewAndOldCards[i].text){
                    await removeCardAsync(originalCard);
                    delete allNewAndOldCards[i]._id;
                    delete allNewAndOldCards[i].id;
                    if(allNewAndOldCards[i].text?.length > 0){
                        await addCardAsync({ newCard: allNewAndOldCards[i], formData, file: null });
                    }
                }
            }else{
                if(allNewAndOldCards[i].text?.length > 0){
                    await addCardAsync({ newCard: allNewAndOldCards[i], formData, file: null });
                }
            }
        }
        setNavigate(true);
        if (location?.state?.from === "signup") {
            engageWithUser(user._id);
        }
    }

    const handleUpdateCard = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();
        handleCard(formData);
    };

    return (
        <ContainerFullWidthHeightComponent>
            {location?.state?.from !== 'signup' && GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            {navigate && <Navigate to='/inbox' />}
            {location?.state?.from !== 'settings' && <ProgressComponent steps={steps.steps} atStep={steps.atStep} />}
            <Container style={{ background: '#363636' }}>
                <Row>
                    <Col xs={6} style={{ background: fruitColors.blueberry.color }}>
                        <FlexComponent>
                            {avatar && <AvatarMapperComponent width={150} avatar={avatar} />}
                            <div style={margins.margin}></div>
                        </FlexComponent>
                    </Col>
                    <Col xs={6} style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10 }}>
                        <FlexComponent>
                            <SpeechBubbleComponent>
                                <p style={{ fontFamily: 'regular' }}>
                                    {user?.goal === "knowledge" ? t("writeBioPrompt") : t("writeBioPromptLanguage")}
                                </p>
                            </SpeechBubbleComponent>
                        </FlexComponent>
                    </Col>
                </Row>
            </Container>
            <div style={{
                background: 'linear-gradient(90deg, #DCE7E8 0% 25%, #FFC120 25% 50%, #FFE5E6 50% 75%, #D5E8F6 75% 100%)',
                width: '100%', height: 20,
            }}>
            </div>
            <div style={{ marginTop: 10, textAlign: "center" }}>
                <Container>
                    <Row>
                        <Col>
                            <form onSubmit={handleUpdateCard} encType="multipart/form-data" target="dummyframe">
                                <FormControl>
                                    {allNewAndOldCards.map((card, index) => (
                                        <HStack>
                                            <Textarea
                                                key={index}
                                                mb={2}
                                                value={card.text}
                                                placeholder={t("writeBioPlaceholder")}
                                                onChange={(e) => {
                                                    const newCards = [ ...allNewAndOldCards ];
                                                    newCards[index].text = e.target.value;
                                                    setAllNewAndOldCards(newCards);
                                                }}
                                                resize="both"
                                            />
                                            <IconButton
                                                aria-label='delete'
                                                colorScheme="gray"
                                                icon={<FaTrash />}
                                                onClick={async() => {
                                                    const newCards = [ ...allNewAndOldCards ];
                                                    const theCard = newCards[index];
                                                    await removeCardAsync(theCard);
                                                    newCards.splice(index, 1);
                                                    setAllNewAndOldCards(newCards);
                                                }} />
                                        </HStack>
                                    ))}
                                </FormControl>

                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Button
                                        colorScheme="teal"
                                        onClick={addNewCard}
                                        style={{ marginRight: 10 }} 
                                    >
                                        <FaPlus size={20} /><span style={{ paddingLeft: 3 }}>{t("addAnotherCard")}</span>
                                    </Button>
                                </Box>

                                <Box display="flex" justifyContent="center" mt={2} mb={4}>
                                    <Button
                                        type="submit"
                                        colorScheme="teal"
                                        disabled={location?.state?.from === 'signup' ? allNewAndOldCards.length === 0 : false}
                                    >
                                        {t("writeBioSubmit")}
                                    </Button>
                                </Box>
                            </form>

                        </Col>
                    </Row>
                </Container>
            </div>
        </ContainerFullWidthHeightComponent>
    );
};
