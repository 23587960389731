// Create a react component with a centered loading spinner

import { Fragment, useEffect } from "react";
import { getUser, useVerifyToken } from "./hook/auth-query";

export const AuthLogicComponent = (props: { children: React.ReactNode, noProtectedSites?: boolean }) => {
    const isAuth = getUser();
    const { data } = useVerifyToken();

    useEffect(() => {
        if (!isAuth?.isOnSignup) {
            if (props.noProtectedSites) {
                if (isAuth?.token) {
                    window.location.href = '/inbox';
                }
            } else {
                // get token from localStorage
                const token = localStorage.getItem('token');
                if (data) {
                    if (isAuth?.token?.length === 0 && token == null) {
                        window.location.href = '/';
                    }
                }
            }
        }
    }, [ data, isAuth, props.noProtectedSites ]);

    return <Fragment>
        {props.children}
    </Fragment>;
};