import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import { Letter } from '../../../ov-common/letter';
import { QueryKeys } from '../../../query-keys';
import { sendLetter, setLast } from '../../../services/letters.service';

// A custom hook for setting the last letter using React Query Mutation
export const useSetLast = () => {
    return useMutation({
        mutationFn: (letterId: string) => setLast(letterId),
    });
};

export interface SendLetterProps {
    toText: string;
    to: string;
    message: string;
    repliedTo: string;
}

export const useSendLetter = () => {
    return useMutation({
        mutationFn: (props: SendLetterProps) => sendLetter(props.toText, props.to, props.message, props.repliedTo),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.Conversation ], (oldData: Letter[]) => {
                if (oldData) {
                    return [ ...oldData, data ];
                }
                return oldData;
            });
        },
    });
};