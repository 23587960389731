// This component is used to create a avatar for the user

import { Box, Button } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";
import { fruitColors } from "../../colors/global-styles";
import { FlexComponent } from "../../components/flex.component";
import { ProgressComponent, Steps } from "../../components/progress-bar.component";
import { ContainerFullWidthHeightComponent } from "../../components/rows/container-full-width-height.component";
import { OneRowComponent } from "../../components/rows/one-row.component";
import { Lang } from "../../lang/lang";
import { getUser } from "../auth/hook/auth-query";
import { Avatar, AvatarComponent } from "../avatar/avatar.component";
import { GoBackButton } from "../letters/send-letters.screen";
import { useUpdateAvatar } from "./hooks/user-query";

export const EditAvatarComponent = () => {
    const myUser = getUser();
    const location = useLocation();
    const [ newAvatar, setNewAvatar ] = useState<Avatar | null>(null);
    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
    const flatListRef = useRef(null);
    const [ clickedGoBack, setClickedGoBack ] = useState(false);
    const { mutate: updateAvatarAction } = useUpdateAvatar();
    const { t } = Lang();

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [ arrayOfOptions, setArrayOfOptions ] = useState<any>({
        avatarStyle: [
            "Circle", "Transparent",
        ],
        topType: [
            "NoHair",
            "Eyepatch",
            "Hat",
            "Hijab",
            "Turban",
            "WinterHat1",
            "WinterHat2",
            "WinterHat3",
            "WinterHat4",
            "LongHairBigHair",
            "LongHairBob",
            "LongHairBun",
            "LongHairCurly",
            "LongHairCurvy",
            "LongHairDreads",
            "LongHairFrida",
            "LongHairFro",
            "LongHairFroBand",
            "LongHairNotTooLong",
            "LongHairShavedSides",
            "LongHairMiaWallace",
            "LongHairStraight",
            "LongHairStraight2",
            "LongHairStraightStrand",
            "ShortHairDreads01",
            "ShortHairDreads02",
            "ShortHairFrizzle",
            "ShortHairShaggyMullet",
            "ShortHairShortCurly",
            "ShortHairShortFlat",
            "ShortHairShortRound",
            "ShortHairShortWaved",
            "ShortHairSides",
            "ShortHairTheCaesar",
            "ShortHairTheCaesarSidePart",
        ],
        accessoriesType:
            [
                "Blank",
                "Kurt",
                "Prescription01",
                "Prescription02",
                "Round",
                "Sunglasses",
                "Wayfarers",
            ],
        hairColor:
            [
                "Auburn",
                "Black",
                "Blonde",
                "BlondeGolden",
                "Brown",
                "BrownDark",
                "PastelPink",
                "Platinum",
                "Red",
                "SilverGray",
            ],

        hatColor: [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White",
        ],
        facialHairType:
            [
                "Blank",
                "BeardMedium",
                "BeardLight",
                "BeardMagestic",
                "MoustacheFancy",
                "MoustacheMagnum",
            ],
        facialHairColor: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "Platinum",
            "Red",
        ],
        clotheType:
            [
                "BlazerShirt",
                "BlazerSweater",
                "CollarSweater",
                "GraphicShirt",
                "Hoodie",
                "Overall",
                "ShirtCrewNeck",
                "ShirtScoopNeck",
                "ShirtVNeck",
            ],
        eyeType:
            [
                "Close",
                "Cry",
                "Default",
                "Dizzy",
                "EyeRoll",
                "Happy",
                "Hearts",
                "Side",
                "Squint",
                "Surprised",
                "Wink",
                "WinkWacky",
            ],
        eyebrowType:
            [
                "Angry",
                "AngryNatural",
                "Default",
                "DefaultNatural",
                "FlatNatural",
                "RaisedExcited",
                "RaisedExcitedNatural",
                "SadConcerned",
                "SadConcernedNatural",
                "UnibrowNatural",
                "UpDown",
                "UpDownNatural",
            ],
        mouthType:
            [
                "Concerned",
                "Default",
                "Disbelief",
                "Eating",
                "Grimace",
                "Sad",
                "ScreamOpen",
                "Serious",
                "Smile",
                "Tongue",
                "Twinkle",
                "Vomit",
            ],
        skinColor:
            [
                "Tanned",
                "Yellow",
                "Pale",
                "Light",
                "Brown",
                "DarkBrown",
                "Black",
            ],
        clotheColor: [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White",
        ],
    });

    const [ myAvatar, setMyAvatar ] = useState<any>({
        avatarStyle: "Transparent",
        topType: "LongHairStraight",
        accessoriesType: "Blank",
        hatColor: "",
        hairColor: "BrownDark",
        facialHairType: "Blank",
        facialHairColor: "",
        clotheType: "BlazerShirt",
        eyeType: "Default",
        eyebrowType: "Default",
        mouthType: "Default",
        skinColor: "Light",
        clotheColor: "",
    });

    const [ indexGlobal, setIndexGlobal ] = useState("topType");

    const [ previousIndex, setPreviousIndex ] = useState("topType");

    const [ currentOption, setCurrentOption ] = useState(arrayOfOptions["topType"]);

    const meny = [
        {
            text: t("avatarScreenTopType"),
            name: "topType",
            type: 0,
        },
        {
            text: t("avatarScreenAccessoriesType"),
            name: "accessoriesType",
            type: 1,
        },
        {
            text: t("avatarScreenHatColor"),
            name: "hatColor",
            type: 1,
        },
        {
            text: t("avatarScreenHairColor"),
            name: "hairColor",
            type: 1,
        },
        {
            text: t("avatarScreenFacialHairType"),
            name: "facialHairType",
            type: 1,
        },
        {
            text: t("avatarScreenFacialHairColor"),
            name: "facialHairColor",
            type: 1,
        },
        {
            text: t("avatarScreenClotheType"),
            name: "clotheType",
            type: 0,
        },
        {
            text: t("avatarScreenClotheColor"),
            name: "clotheColor",
            type: 1,
        },
        {
            text: t("avatarScreenEyeType"),
            name: "eyeType",
            type: 0,
        },
        {
            text: t("avatarScreenEyebrowType"),
            name: "eyebrowType",
            type: 0,
        },
        {
            text: t("avatarScreenMouthType"),
            name: "mouthType",
            type: 0,
        },
        {
            text: t("avatarScreenSkinColor"),
            name: "skinColor",
            type: 0,
        },
    ];

    const [ accept, setAccept ] = useState<any[]>([ "accessoriesType", "hairColor", "facialHairType" ]);
    const avatarProperties = myUser?.avatar;

    useEffect(() => {
        if (avatarProperties?.accepted != null && avatarProperties?.properties != null) {
            setMyAvatar(avatarProperties?.properties);
            setAccept(avatarProperties?.accepted);
        }
    }, [ avatarProperties ]);

    const removeItem = (input: any, item: any) => {
        input = input.filter((i: any) => i !== item);
        return input;
    };

    const acceptFunc = (item: any) => {

        let name = "";
        let index = 0;
        let array = accept;
        if (indexGlobal === "topType") {

            //accessoriesType
            if ([ "Eyepatch" ].indexOf(item) > -1) {
                index = array.indexOf("accessoriesType");
                if (index > -1) {
                    array = removeItem(array, "accessoriesType");
                }
            } else {
                array = [ ...array, "accessoriesType" ];
            }

            //facialHair
            name = "facialHairType";
            const name1 = "facialHairColor";
            if ([ "Hijab" ].indexOf(item) > -1) {
                index = array.indexOf(name);
                if (index > -1) {
                    array = removeItem(array, name);
                    array = removeItem(array, name1);
                }
            } else {
                array = [ ...array, name ];
            }

            //facialHair
            name = "hairColor";
            console.log(item.includes("NoHair"));
            if (item.indexOf("Hair") == -1 || item.includes("NoHair")) {
                index = array.indexOf(name);
                if (index > -1) {
                    array = removeItem(array, name);
                }
            } else {
                array = [ ...array, name ];
            }

            name = "hatColor";
            if (item.indexOf("Hat") == -1 || item == "Hat") {
                index = array.indexOf(name);
                if (index > -1) {
                    array = removeItem(array, name);
                }
            } else {
                array = [ ...array, name ];
            }

            setAccept(array);

        }

        if (indexGlobal == "facialHairType") {
            name = "facialHairColor";
            if (item.indexOf("Blank") > -1) {
                index = accept.indexOf(name);
                if (index > -1) {
                    const array = removeItem(accept, name);
                    setAccept(array);
                }
            } else {
                setAccept([ ...accept, name ]);
            }
        }

        if (indexGlobal == "clotheType") {

            name = "clotheColor";
            if (item.includes("Blazer")) {
                index = accept.indexOf(name);
                if (index > -1) {
                    const array = removeItem(accept, name);
                    setAccept(array);
                }
            } else {
                setAccept([ ...accept, name ]);
            }
        }

    };

    const _renderMeny = (item: any, index: number) => {
        if (item.type == 1 && accept.indexOf(item.name) == -1) {
            return null;
        }
    
        return (
            <Box
                style={{ marginLeft: index === 0 ? 0 : 12, cursor: 'pointer' }}
                onClick={() => {
                    setIndexGlobal(item.name);
                    setCurrentOption(arrayOfOptions[item.name]);
    
                    setTimeout(() => {
                        if (flatListRef != null && flatListRef.current != null) {
                            //flatListRef.current.scrollToIndex({ animated: true, index: 0 });
                        }
                    }, 500);
                }}
            >
                <Button
                    variant="solid"
                    colorScheme="gray"
                    bg={indexGlobal === item.name ? fruitColors.blueberry.color : 'gray.300'}
                    color={'black'}
                    justifyContent="center"
                    alignItems="center"
                    px={4}
                    py={2}
                    mt={2}
                    mb={2}
                >
                    <p style={{ margin: 0 }}>{item.text}</p>
                </Button>
            </Box>
        );
    };

    const _renderOption = (item: any) => {
        const normalizedArray: any = {};
        for (const key in myAvatar) {
            normalizedArray[key] = myAvatar[key];
        }
        normalizedArray[indexGlobal] = item;
        return (
            <div style={{
                position: 'relative', maxHeight: '150px',
                overflow: 'hidden',
            }}>
                <div
                    style={{ display: 'inline-block', width: 350, height: 200, zIndex: 1000 }}
                    onClick={() => {
                        acceptFunc(item);
                        const obj: any = {};
                        obj[indexGlobal] = item;
                        setMyAvatar({ ...myAvatar, ...obj });
                    }}>
                </div>
                <div style={{ position: 'absolute', top: 0, left: windowWidth > 1000 ? 0 : '-48px', zIndex: -1000 }}>
                    <AvatarComponent
                        style={{ width: -100, height: -100 }}
                        special={true}
                        remove={true}
                        avatarStyle={normalizedArray.avatarStyle}
                        topType={normalizedArray.topType}
                        accessoriesType={normalizedArray.accessoriesType}
                        hatColor={normalizedArray.hatColor}
                        hairColor={normalizedArray.hairColor}
                        facialHairType={normalizedArray.facialHairType}
                        facialHairColor={normalizedArray.facialHairColor}
                        clotheType={normalizedArray.clotheType}
                        clotheColor={normalizedArray.clotheColor}
                        eyeType={normalizedArray.eyeType}
                        eyebrowType={normalizedArray.eyebrowType}
                        mouthType={normalizedArray.mouthType}
                        skinColor={normalizedArray.skinColor}
                    />
                </div>
            </div>
        );
    };
    const steps = new Steps(5, 2);
    return (
        <ContainerFullWidthHeightComponent>
            {GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            {newAvatar && location.state?.from === "signup" ? <Navigate to='/language' state={{ avatar: newAvatar, from: 'signup' }}  />
                : newAvatar ? <Navigate to='/inbox'  /> : null}
            {myUser?.isOnSignup && <ProgressComponent steps={steps.steps} atStep={steps.atStep} />}
            <Container>
                <Row>
                    <Col xs={12} style={{ background: "#F6F5F4" }}>
                        <FlexComponent>
                            <AvatarComponent
                                style={{ width: 350, height: 200 }}
                                special={true}
                                remove={true}
                                avatarStyle={myAvatar.avatarStyle}
                                topType={myAvatar.topType}
                                accessoriesType={myAvatar.accessoriesType}
                                hatColor={myAvatar.hatColor}
                                hairColor={myAvatar.hairColor}
                                facialHairType={myAvatar.facialHairType}
                                facialHairColor={myAvatar.facialHairColor}
                                clotheType={myAvatar.clotheType}
                                clotheColor={myAvatar.clotheColor}
                                eyeType={myAvatar.eyeType}
                                eyebrowType={myAvatar.eyebrowType}
                                mouthType={myAvatar.mouthType}
                                skinColor={myAvatar.skinColor}
                            />
                        </FlexComponent>
                    </Col>
                </Row>
            </Container>
            <div style={{
                background: 'linear-gradient(90deg, #DCE7E8 0% 25%, #FFC120 25% 50%, #FFE5E6 50% 75%, #D5E8F6 75% 100%)',
                width: '100%', height: 10,
            }}>
            </div>
            <Container>
                <Row>
                    <div style={{ overflowY: 'hidden', maxHeight: '200px', display: 'flex' }}>
                        {meny.map((item: any, index: number) => {
                            const meny = _renderMeny(item, index);
                            if(meny == null) {
                                return null;
                            }
                            return (
                                <Col key={(Math.random() * 10000).toString()}>
                                    {meny}
                                </Col>
                            );
                        })}
                    </div>
                </Row>
                <Row style={{ maxHeight: '300px', overflowY: 'scroll', border: "3px solid lightgray", borderRadius: 10 }}>
                    {currentOption.map((item: any, index: number) => {
                        return (
                            <Col xs={4} key={index + "avatar"}>

                                {_renderOption(item)}
                            </Col>
                        );
                    })}
                </Row>
            </Container>
            <OneRowComponent>
                <Button
                    mt={4}
                    colorScheme="teal"
                    onClick={() => {
                        const newAvatar = new Avatar(accept, new Date(), myAvatar);
                        updateAvatarAction(newAvatar);
                        setNewAvatar(newAvatar);
                    }}
                >
                    {location.state?.from === 'signup' ? t("create") : t("save")}
                </Button>
            </OneRowComponent>
        </ContainerFullWidthHeightComponent>
    );
};