import { Divider, Stack } from "@chakra-ui/react";

interface MenuItemsProps {
	children?: JSX.Element | JSX.Element[];
}

const MenuItems = ({ children }: MenuItemsProps) => {
    return (
        <Stack width={"100%"}>
            <Divider
                orientation="horizontal"
                mt={"1rem"}
                marginBottom={"0.5rem"}
                marginTop={"0.5rem"}
            />
            <Stack divider={<Divider />}>{children}</Stack>
            <Divider
                orientation="horizontal"
                mt={"1rem"}
                marginBottom={"0.5rem"}
                marginTop={"0.5rem"}
            />
        </Stack>
    );
};

export default MenuItems;
