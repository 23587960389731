import { format, Locale } from 'date-fns';
import { enGB } from 'date-fns/locale';

export function formatDate(date: Date | string, locale: Locale = enGB) {
    // Convert the date using date-fns and format it as 'dd MMM yyyy'
    return format(new Date(date), 'dd MMM yyyy', { locale: locale });
}

export function formatDateYYYYMMDD(date: Date | string) {
    // Create a new date object from the passed-in value
    const d = new Date(date);

    // Extract the year, month, and day components
    const year: string | number = d.getFullYear();
    let month: string | number = d.getMonth() + 1; // January is 0
    let day: string | number = d.getDate();

    // Pad the month and day with leading zeroes if needed
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Return the formatted date string
    return year + "-" + month + "-" + day;
}

// Write a function that check if a date is older than 4 hours
export function isOlderThan4Hours(date: Date | string) {
    // Create a new date object from the passed-in value
    const d = new Date(date);

    // Get the current time
    const now = new Date();

    // Calculate the difference in milliseconds
    const diff = now.getTime() - d.getTime();

    // Calculate the difference in hours
    const hours = diff / (1000 * 60 * 60);

    // Return true if the difference is greater than 4 hours
    return hours > 4;
}