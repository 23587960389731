import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { addMonths, format, subMonths } from "date-fns"; // Import necessary functions
import { sv } from "date-fns/locale";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Lang } from "../../lang/lang";

interface MonthSelectorProps {
    currentDate?: Date;
    onChange?: (date: Date) => void;
}

const MonthSelector = ({ currentDate: propCurrentDate, onChange }: MonthSelectorProps) => {
    const { lang } = Lang();
    // Initialize state with currentDate prop
    const [ currentDate, setCurrentDate ] = useState(propCurrentDate || new Date());

    // Sync the state with the prop if the prop changes
    useEffect(() => {
        if (propCurrentDate) {
            setCurrentDate(propCurrentDate);
        }
    }, [ propCurrentDate ]);

    // Function to handle changing the month
    const changeMonth = (direction: "prev" | "next") => {
        const newDate =
      direction === "prev"
          ? subMonths(currentDate, 1)
          : addMonths(currentDate, 1);

        setCurrentDate(newDate);

        // Trigger onChange callback
        if (onChange) {
            onChange(newDate);
        }
    };
    console.log(lang);
    console.log(sv);
    // Format month and year for display using date-fns
    const formattedMonthYear = format(currentDate, "MMMM yyyy", { locale: lang });

    return (
        <Flex align="center" justify="center">
            {/* Left Arrow Button */}
            <IconButton
                aria-label="Previous Month"
                icon={<FaChevronLeft />}
                onClick={() => changeMonth("prev")}
                variant="ghost"
                size="lg"
            />

            {/* Display current month and year */}
            <Box mx={4} textAlign="center" pt={3}>
                <Text fontSize="xl" fontWeight="bold">
                    {formattedMonthYear}
                </Text>
            </Box>

            {/* Right Arrow Button */}
            <IconButton
                aria-label="Next Month"
                icon={<FaChevronRight />}
                onClick={() => changeMonth("next")}
                variant="ghost"
                size="lg"
            />
        </Flex>
    );
};

export default MonthSelector;
