import { Container, Flex, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FaClipboard, FaInfoCircle, FaPercent, FaSpellCheck } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import MonthSelector from "../../components/date/month-selector";
import { GradeExplanationInfo } from "../../components/grade/grade-explanation";
import { Lang } from "../../lang/lang"; // Import the Lang component
import { Letter } from "../../ov-common/letter";
import { getUser } from "../auth/hook/auth-query";
import { AvatarMapperComponent } from "../avatar/avatar.component";
import { GoBackButton } from "../letters/send-letters.screen";
import { useGetLetters } from "./hooks/grade.hooks";

export const GradeScreen = () => {
    const { t } = Lang(); // Access translation function and language setter
    const [ clickedGoBack, setClickedGoBack ] = useState(false);
    const location = useLocation();
    const user = getUser();
    
    const fDate = new Date();
    fDate.setDate(1); // Set to the first day of the month
    const [ fromDate, setFromDate ] = useState(fDate);
    
    const sDate = new Date();
    sDate.setDate(1); // Set to the first day of the next month
    sDate.setMonth(sDate.getMonth() + 1);
    const [ toDate, setToDate ] = useState(sDate);
    
    const { data: letters } = useGetLetters(user?._id, fromDate, toDate);

    // Function to calculate average of numeric fields from the metrics
    const calculateAverages = (letters?: Letter[]) => {
        if (!letters || letters.length === 0) return null;

        const metricsSum = {
            totalScore: 0,
            maxScore: 0,
            percentageScore: 0,
        };

        let count = 0;

        letters.forEach(letter => {
            if (letter?.metrics) {
                const metrics = letter.metrics;

                // Sum up all the numeric properties
                metricsSum.totalScore += metrics.totalScore;
                metricsSum.maxScore += metrics.maxScore;
                metricsSum.percentageScore += metrics.totalScore / metrics.maxScore;

                count += 1;
            }
        });

        // Calculate the averages
        return {
            totalScore: metricsSum.totalScore,
            maxScore: metricsSum.maxScore,
            averageScorePerLetter: metricsSum.percentageScore / count,
        };
    };

    const averages = calculateAverages(letters);

    return (
        <>
            {GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            <Container pl={5} pr={5}>  
                <Flex justifyContent={"center"} mt={-10}>
                    {user?.avatar != null && <AvatarMapperComponent
                        width={80}
                        avatar={user?.avatar}
                    />}
                </Flex>
                <Flex justifyContent={"center"} mt={-8}>
                    <Text fontSize={"1rem"}>{user?.anvandarnamn}</Text>
                </Flex>
                <MonthSelector 
                    currentDate={fromDate} 
                    onChange={(date) => {
                        setFromDate(date);
                        const newToDate = new Date(date);
                        newToDate.setMonth(newToDate.getMonth() + 1);
                        setToDate(newToDate);
                    }} 
                />

                <Container w={"100%"}>
                    <Heading
                        as="h3"
                        fontSize={"2xl"}
                        fontWeight={"500"}
                        float={"left"}
                    >
                        {t("grade-this-month")} {/* Translation for "This month" */}
                    </Heading>
                </Container>

                {/* Display all the calculated averages */}
                <Container  w={"100%"} display={"flow-root"}>
                    {averages ? (
                        <>
                            <GradeExplanationInfo
                                title={t("grade-total-score")}
                                titleExplanation={`${averages.totalScore.toFixed(2)}`}
                                icon={<FaSpellCheck />}
                            />
                            <GradeExplanationInfo
                                title={t("grade-max-score")}
                                titleExplanation={`${averages.maxScore.toFixed(2)}`}
                                icon={<FaClipboard />}
                            />
                            <GradeExplanationInfo
                                title={t("grade-average-grade")}
                                titleExplanation={`${(averages.averageScorePerLetter * 100).toFixed(2)}%`}
                                icon={<FaPercent />}
                            />
                        </>
                    ) : (
                        <GradeExplanationInfo
                            title={t("grade-nothing-to-show")}
                            titleExplanation={t("grade-nothing-to-show")}
                            icon={<FaInfoCircle />}
                        />
                    )}
                </Container>
            </Container>
        </>
    );
};
