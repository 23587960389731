import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

export interface IGradeExplanationProps {
	title: string;
	titleExplanation?: string | number | null | undefined;
	comment?: string | string[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon: any;
	isLoading?: boolean;
	children?: JSX.Element;
}

export const GradeExplanationInfo: React.FC<IGradeExplanationProps> = (props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const lineRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.comment || props.children) {
            const element1Height = containerRef.current!.clientHeight;
			lineRef.current!.style.height = `${element1Height - 40}px`;
        }
    }, [ props.comment, props.children ]);

    return (
        <div
            ref={containerRef}
            style={{
                marginBottom: "1rem",
                borderRadius: "5%",
            }}
        >
            <Stack direction={"row"} alignItems={"start"}>
                <Box mr={"1rem"}>
                    <Flex
                        justify="center"
                        align="center"
                        h="40px"
                        w="40px"
                        borderRadius="50%"
                        backgroundColor={"lighghtgray"}
                    >
                        {props.icon}
                    </Flex>

                    {(props.comment || props.children) && (
                        <div
                            ref={lineRef}
                            style={{
                                borderLeft: `solid ${"black"} 2px`,
                                transform: "translate(20px)",
                            }}
                        />
                    )}
                </Box>
                <Stack>
                    <Stack direction="row" mt="8px">
                        <Text
                            color={"black"}
                            fontWeight="medium"
                            noOfLines={1}
                            m={0}
                        >
                            {props.title}
                        </Text>
                    </Stack>
                    {props.titleExplanation && (
                        <Text
                            m={0}
                            color={"#a3a3a3"}
                        >
                            {props.titleExplanation}
                        </Text>
                    )}
                    {props.comment && <Comment comment={props.comment} />}
                    {props.children}
                </Stack>
            </Stack>
        </div>
    );
};

const Comment = ({ comment }: { comment: string | string[] }) => {
    if (Array.isArray(comment)) {
        return<> {comment.map((c, i) => (
            <Text
                key={i}
                color={"lightgray"}
                fontWeight="medium"
            >
                {c}
            </Text>
        ))} </>;
    }
    return (
        <Text
            color={"lightgray"}
            fontWeight="medium"
        >
            {comment}
        </Text>
    );
};
