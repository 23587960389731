import { sendPostWithToken } from "../http-utilities/http-utilities";
import { Conversation } from "../ov-common/conversation";
import { Letter } from "../ov-common/letter";
import { Upload } from "../ov-common/models/models";
import env from "./../env.json";

// Write a function that takes toText, to and message as parameters and use sendPostWithToken to send a post request to /brev/skapa and uses token from localStorage. return Promise of type SendLetter.
export const sendLetter = (toText: string, to: string, message: string, repliedTo: string): Promise<Letter> => {
    return sendPostWithToken('brev/skicka', { toText, to, message, repliedTo });
};

// write a function that takes userId and repicipentId as parameters and use sendPostWithToken to send a post request to konversationer/hamtaKonversation return a Promise of Letter[]
export const getConversation = (userId: string, recipientId: string): Promise<Letter[]> => {
    return sendPostWithToken('konversationer/hamtaKonversation', { _id: userId, id: recipientId });
};

export const getLatestConversation = (limit: number): Promise<Conversation[]> => {
    return sendPostWithToken('konversationer/hamtaSenasteKonversationerna', { limit });
};

// set last to true and use sendPostWithToken to send a post request to brev/skicka. return Promise of type Letter
export const setLast = (letterId: string): Promise<Letter> => {
    return sendPostWithToken('brev/last', { id: letterId });
};

export const getLetters = (userId: string, fromDate: Date, toDate: Date): Promise<Letter[]> => {
    return sendPostWithToken('brev/hamtaSenasteBreven', { userId, fromDate, toDate });
};

export const sendLetterAndUploadFile = async(toText: string, to: string, message: string, formData: FormData, file: File, repliedTo: string): Promise<Letter | undefined> => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token || !userId) {
        return;
    }
    const letter = await sendLetter(toText, to, message, repliedTo);

    const upload = await fetch(
        env.backend + 'brev/upload?id=' + letter._id +
        '&filename=' + file.name + '&fromUserId=' + userId + '&toUserId=' + to,
        {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'bearer ' + token,
            }),
            body: formData,
        }
    );

    const uploadData = await upload.json() as Upload;

    letter.upload = uploadData;

    return letter;
};