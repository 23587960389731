// Create a ReactJS component to display a full width and height container with containerStyle

import { containerStyle } from '../../colors/global-styles';

export const OneRowComponent = (props: any) => {
    return (
        <div style={containerStyle.containerRow}>
            {props.children}
        </div>
    );
};