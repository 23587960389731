// This component is used to display progress bars for props.steps and props.atStep

import { Col, Container, Row } from "react-bootstrap";
import { Lang } from "../lang/lang";
import './css/progress-bar.component.css';

export class Steps {
    public steps: number;
    public atStep: number;

    constructor(steps: number, atStep: number) {
        this.steps = steps;
        this.atStep = atStep;
    }
}

export const ProgressComponent = (props: Steps) => {
    const { t } = Lang(); 
    // create a div with some margins
    const steps = props.steps;
    const atStep = props.atStep;
    return (
        <Container>
            <Row>
                <Col>
                    <div className="progress-container">
                        <div className='progress-bar-fill' style={{ width: `${(atStep / steps) * 100}%` }}></div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>{atStep} {t("progressStep")} {steps}</p>
                </Col>
            </Row>
        </Container>
    );
};