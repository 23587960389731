// create react style object bgColor with gradient white to lightgray. Another grayColor and blueColor and greenColor and redColor

export function GetBoxColor(darkMode: boolean | null | undefined){
    if(darkMode){
        return '#343541 !important';
    }else{
        return "white";
    }
}

export function GetMainBackgroundColor(darkMode: boolean | null | undefined){
    if(darkMode){
        return '#2A2B32 !important';
    }else{
        return "#F5F8FA !important";
    }
}

export const globalStyles = {
    bgColor: {
        background: 'linear-gradient(to bottom, #1C172B, #28223E)',
    },
    secondBgColor: {
        background: 'linear-gradient(to bottom, white, #e6e6e6)',
    },
    grayColor: {
        color: '#ebebeb',
    },
    darkGrayColor: {
        color: '#787878',
    },
    blueColor: {
        color: '#2196F3',
    },
    greenColor: {
        color: '#4CAF50',
    },
    redColor: {
        color: '#F44336',
    },
    coloredBgColor: {
        background: 'linear-gradient(90deg, rgba(255,225,53,1) 0% 25%, rgba(102,180,71,1) 25% 50%, rgba(252,90,141,1) 50% 75%, rgba(79,134,247,1) 75% 100%)',
    },
};

// Create a nice rounded button with a gradient background #2196F3 and a shadow

export const buttonStyle = {
    blueButton: {
        borderRadius: '3px',
        background: '#0b57d0',
        boxShadow: '0 12px 20px -10px rgba(33, 150, 243, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(33, 150, 243, 0.2)',
        color: '#FFFFFF',
        padding: '12px 30px',
        margin: '10px 1px',
        border: 'none',
        cursor: 'pointer',
        minWidth: '160px',
        transition: 'all 150ms ease 0s',
        letterSpacing: 'unset',
        willChange: 'box-shadow, transform',
        '&:hover': {
            transform: 'translate3d(0, 1px, 0)',
            boxShadow: '0 16px 24px -10px rgba(33, 150, 243, 0.28), 0 7px 30px 5px rgba(0, 0, 0, 0.12), 0 12px 10px -5px rgba(33, 150, 243, 0.2)',
        },
        width: '100%',
    },
    greenButton: {
        borderRadius: '3px',
        background: 'rgba(102,180,71,1)',
        boxShadow: '0 12px 20px -10px rgba(33, 150, 243, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(33, 150, 243, 0.2)',
        color: '#FFFFFF',
        padding: '12px 30px',
        margin: '10px 1px',
        border: 'none',
        cursor: 'pointer',
        minWidth: '160px',
        transition: 'all 150ms ease 0s',
        letterSpacing: 'unset',
        willChange: 'box-shadow, transform',
        '&:hover': {
            transform: 'translate3d(0, 1px, 0)',
            boxShadow: '0 16px 24px -10px rgba(33, 150, 243, 0.28), 0 7px 30px 5px rgba(0, 0, 0, 0.12), 0 12px 10px -5px rgba(33, 150, 243, 0.2)',
        },
        width: '100%',
    },
    pinkButton: {
        borderRadius: '3px',
        background: 'rgba(252,90,141,1)',
        boxShadow: '0 12px 20px -10px rgba(33, 150, 243, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(33, 150, 243, 0.2)',
        color: '#FFFFFF',
        padding: '12px 30px',
        margin: '10px 1px',
        border: 'none',
        cursor: 'pointer',
        minWidth: '160px',
        transition: 'all 150ms ease 0s',
        letterSpacing: 'unset',
        willChange: 'box-shadow, transform',
        '&:hover': {
            transform: 'translate3d(0, 1px, 0)',
            boxShadow: '0 16px 24px -10px rgba(33, 150, 243, 0.28), 0 7px 30px 5px rgba(0, 0, 0, 0.12), 0 12px 10px -5px rgba(33, 150, 243, 0.2)',
        },
        width: '100%',
    },
    defaultButton: {
        borderRadius: '3px',
        background: 'white',
        border: '0.0625rem solid #a9acaa',
        color: 'black',
        padding: '12px 30px',
        margin: '10px 1px',
        cursor: 'pointer',
        minWidth: '160px',
        transition: 'all 150ms ease 0s',
        letterSpacing: 'unset',
        willChange: 'box-shadow, transform',
        '&:hover': {
            transform: 'translate3d(0, 1px, 0)',
            boxShadow: '0 16px 24px -10px rgba(236, 64, 122, 0.28), 0 7px 30px 5px rgba(0, 0, 0, 0.12), 0 12px 10px -5px rgba(236, 64, 122, 0.2)',
        },
        width: '100%',
    },
    // Create a gray button

    grayButton: {
        borderRadius: '3px',
        background: '#9E9E9E',
        boxShadow: '0 12px 20px -10px rgba(158, 158, 158, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(158, 158, 158, 0.2)',
        color: '#FFFFFF',
        padding: '12px 30px',
        margin: '10px 1px',
        border: 'none',
        cursor: 'pointer',
        minWidth: '160px',
        transition: 'all 150ms ease 0s',
        letterSpacing: 'unset',
        willChange: 'box-shadow, transform',
        '&:hover': {
            transform: 'translate3d(0, 1px, 0)',
            boxShadow: '0 16px 24px -10px rgba(158, 158, 158, 0.28), 0 7px 30px 5px rgba(0, 0, 0, 0.12), 0 12px 10px -5px rgba(158, 158, 158, 0.2)',
        },
        width: '100%',
    },
};

// Create a nice heavy rounded input with border color and border width of globalStyle.grayColor.color and some transparency and a shadow

export const inputStyle = {
    input: {
        borderRadius: '3px',
        background: 'transparent',
        boxShadow: '0 12px 20px -10px rgba(158, 158, 158, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(158, 158, 158, 0.2)',
        color: 'black',
        padding: '12px 30px',
        margin: '10px 1px',
        border: '1px solid #ced4da',
        cursor: 'pointer',
        minWidth: '160px',
        transition: 'all 150ms ease 0s',
        letterSpacing: 'unset',
        willChange: 'box-shadow, transform',
        '&:hover': {
            transform: 'translate3d(0, 1px, 0)',
            boxShadow: '0 16px 24px -10px rgba(158, 158, 158, 0.28), 0 7px 30px 5px rgba(0, 0, 0, 0.12), 0 12px 10px -5px rgba(158, 158, 158, 0.2)',
        },
        // placeholder color black
        '::placeholder': {
            color: 'black',
        },
    },
};

// Create a style for container, one row with 1 column, one row with 2 columns, one row with 3 columns, one row with 4 columns in React JS css

export const containerStyle = {
    container: {
        width: '100%',
        height: '100vh',
    },
    containerRow: {
        width: '100%',
        display: 'flex',
        // align cente 
        alignItems: 'center',
        // justify center
        justifyContent: 'center',
        marginBottom: 10,
    },
    containerColumn: {
        width: '100%',
        display: 'flex',
    },
    containerRow1: {
        width: '100%',
        display: 'flex',
    },
    containerRow2: {
        width: '100%',
        display: 'flex',
    },
    containerRow3: {
        width: '100%',
        display: 'flex',
    },
};

export const margins = {
    margin: {
        margin: '15px',
        alignItems: 'center',
        // justify center
        justifyContent: 'center',
    },
};

export const fruitColors = {
    banana: {
        color: "#ffe135",
    },
    apple: {
        color: "#66b447",
    },
    strawberry: {
        color: "#fc5a8d",
    },
    blueberry: {
        color: "#DCE7E8",
    },
};