import { Avatar } from "./avatar";

export class User {
    public _id: string;
    public namn: string;
    public alder: number;
    public epost: string;
    public anvandarnamn: string;
    public losenord: string;
    public pushToken: string;
    public intressen: any;
    public konversation: any;
    public datum?: Date;
    public image: string;
    public senastAktiv?: Date;
    public klubbMedlemDatum?: Date;
    public klubbMedlem: boolean;
    public anmalan: number;
    public adress: string;
    public telefon: string;
    public fullNamn: string;
    public personnummer: string;
    public period: string;
    public gifts: any;
    public gender: number;
    public birthday?: Date;
    public birthdayVisible: boolean;
    public labels: any;
    public tags: string[];
    public tagsColor: string[];
    public lang: string[];
    public country: string[];
    public lan: string[];
    public avatar: Avatar | null;
    public upPoints: number;
    public downPoints: number;
    public level: number;
    public bottleVisning: number;
    public senastBottleVisning?: Date;
    public langIndex: string;
    public device: string;
    public trial: boolean;
    public isAdmin = false;
    public isOnSignup = false;
    public token: string;
    public expiresIn: number;
    public goal: "language" | "knowledge" = "language";

    constructor(
        _id: string,
        namn: string, alder: number, epost: string, anvandarnamn: string,
        losenord: string, pushToken: string,
        intressen: any,
        konversation: any,
        datum: Date, image: string, senastAktiv: Date,
        klubbMedlemDatum: Date, klubbMedlem: boolean,
        anmalan: number, adress: string, telefon: string, fullNamn: string,
        personnummer: string, period: string,
        gifts: any,
        gender: number, birthday: Date, birthdayVisible: boolean,
        labels: any,
        tags: string[], tagsColor: string[],
        lang: string[], country: string[],
        lan: string[],
        avatar: Avatar,
        upPoints: number, downPoints: number,
        level: number, bottleVisning: number,
        senastBottleVisning: Date,
        langIndex: string,
        device: string,
        trial: boolean,
        isAdmin = false,
        isOnSignup = false,
        token: string,
        expiresIn: number) {
        this._id = _id;
        this.namn = namn;
        this.alder = alder;
        this.epost = epost;
        this.anvandarnamn = anvandarnamn;
        this.losenord = losenord;
        this.pushToken = pushToken;
        this.intressen = intressen;
        this.konversation = konversation;
        this.datum = datum;
        this.image = image;
        this.senastAktiv = senastAktiv;
        this.klubbMedlemDatum = klubbMedlemDatum;
        this.klubbMedlem = klubbMedlem;
        this.anmalan = anmalan;
        this.adress = adress;
        this.telefon = telefon;
        this.fullNamn = fullNamn;
        this.personnummer = personnummer;
        this.period = period;
        this.gifts = gifts;
        this.adress = adress;
        this.gender = gender;
        this.birthday = birthday;
        this.birthdayVisible = birthdayVisible;
        this.labels = labels;
        this.tags = tags;
        this.tagsColor = tagsColor;
        this.lang = lang;
        this.country = country;
        this.lan = lan;
        this.avatar = avatar;
        this.upPoints = upPoints;
        this.downPoints = downPoints;
        this.level = level;
        this.bottleVisning = bottleVisning;
        this.senastBottleVisning = senastBottleVisning;
        this.langIndex = langIndex;
        this.device = device;
        this.trial = trial;
        this.isAdmin = isAdmin;
        this.isOnSignup = isOnSignup;
        this.token = token;
        this.expiresIn = expiresIn;
    }
}

export class UserListItem {
    public _id: string;
    public amne: string | null;
    public text: string | null;
    public anvandarnamn: string;
    public senastAktiv: Date | null | undefined;
    public alder: number;
    public avatar: Avatar | null;

    constructor(_id: string, amne: string | null, text: string | null, anvandarnamn: string, senastAktiv: Date | null | undefined, alder: number, avatar: Avatar | null) {
        this._id = _id;
        this.amne = amne;
        this.text = text;
        this.anvandarnamn = anvandarnamn;
        this.senastAktiv = senastAktiv;
        this.alder = alder;
        this.avatar = avatar;
    }
}