export class CardBio {
    public id: string | null | undefined = null;
    public _id: string | null | undefined = null;
    public amne: string;
    public text: string;
    public date: Date;
    public anvandareID: string;
    public url?: string | null | undefined = null;

    constructor(id: string | null, amne: string, text: string, date: Date, anvandareID: string) {
        this.amne = amne;
        this.id = id;
        this.text = text;
        this.date = date;
        this.anvandareID = anvandareID;
    }
}
