// Write generel http post function that takes endpoint and body as parameters and returns an Promise of type any. Create the url from env.json + endpoint.
// use axios

import axios from 'axios';
import env from '../env.json';

export const sendPost = (endpoint: string, body: any): Promise<any> => {
    return axios.post(`${env.backend}${endpoint}`, body).then(response => response.data);
};

export const sendPostWithToken = (endpoint: string, body: any = {}): Promise<any> => {
    // Get token from local storage
    const token = localStorage.getItem('token');
    // add token to headers of request authorization header: bearer token
    const headers = {
        headers: {
            authorization: `bearer ${token}`,
        },
    };
    // send post request with token
    return axios.post(`${env.backend}${endpoint}`, body, headers).then(response => response.data);
};