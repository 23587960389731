import { SkeletonCircle, SkeletonText, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";

export const AVATAR_URL = "https://avataaars.io/";

export const AvatarComponentMemo = (props: any) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    let settings = '?';
    settings += "avatarStyle=" + props.avatarStyle;
    settings += "&topType=" + props.topType;
    settings += "&accessoriesType=" + props.accessoriesType;
    settings += "&hatColor=" + props.hatColor;
    settings += "&hairColor=" + props.hairColor;
    settings += "&facialHairType=" + props.facialHairType;
    settings += "&facialHairColor=" + props.facialHairColor;
    settings += "&clotheType=" + props.clotheType;
    settings += "&eyeType=" + props.eyeType;
    settings += "&eyebrowType=" + props.eyebrowType;
    settings += "&mouthType=" + props.mouthType;
    settings += "&skinColor=" + props.skinColor;

    settings += "&clotheColor=" + props.clotheColor;

    return (
        <div style={{ marginLeft: -50, marginRight: -50 }}>
            {isLoading ?
                <Container>
                    <Row>
                        <Col>
                            <Spinner size={"lg"} />
                        </Col>
                    </Row>
                </Container>
                :
                null
            }
            <iframe onLoad={() => {
                setIsLoading(false);
            }} src={AVATAR_URL + settings} style={{ border: 'none' }}></iframe>
        </div>
    );
};

export const AvatarComponent = React.memo(AvatarComponentMemo);

export class AvatarProperties {
    public avatarStyle: string;
    public topType: string;
    public accessoriesType: string;
    public hatColor: string;
    public hairColor: string;
    public facialHairType: string;
    public facialHairColor: string;
    public clotheType: string;
    public clotheColor: string;
    public eyeType: string;
    public eyebrowType: string;
    public mouthType: string;
    public skinColor: string;

    constructor(avatarStyle: string,
        topType: string,
        accessoriesType: string,
        hatColor: string,
        hairColor: string, facialHairType: string,
        facialHairColor: string, clotheType: string,
        eyeType: string,
        eyebrowType: string,
        mouthType: string,
        skinColor: string,
        clotheColor: string) {
        this.avatarStyle = avatarStyle;
        this.topType = topType;
        this.accessoriesType = accessoriesType;
        this.hatColor = hatColor;
        this.hairColor = hairColor;
        this.facialHairType = facialHairType;
        this.facialHairColor = facialHairColor;
        this.clotheType = clotheType;
        this.clotheColor = clotheColor;
        this.eyeType = eyeType;
        this.eyebrowType = eyebrowType;
        this.mouthType = mouthType;
        this.skinColor = skinColor;
        this.clotheColor = clotheColor;
    }
}

export class Avatar {
    public accepted: any[];
    public date: Date;
    public properties: AvatarProperties;

    constructor(accepted: any[], date: Date, properties: AvatarProperties) {
        this.accepted = accepted;
        this.date = date;
        this.properties = properties;
    }
}
<Row>
    <Col>
        <FaSpinner
            size={30}
            color={'black'}
        />
    </Col>
</Row>;
export const AvatarMapperComponent = (props: { avatar: Avatar | null, width?: number | string, height?: number}) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    if (props.avatar == null) return (<div></div>);
    const settings = getAvatarUrl(props);

    return (
        <div style={{ width: props.width, height: props.height }}>
            {isLoading ? (
                <>
                    <SkeletonCircle size="10" />
                    <SkeletonText mt="4" noOfLines={1} width="50%" />
                </>
            ) : null}
            <iframe
                onLoad={() => {
                    setIsLoading(false);
                }}
                title={(Math.random() * 1000).toString()}
                src={"https://avataaars.io/" + settings}
                style={{ border: 'none', width: props.width, height: props.height }}
                allowFullScreen={true}
            ></iframe>
        </div>
    );
};

export function getAvatarUrl(props: { avatar: Avatar | null | undefined }) {
    let settings = '?';
    settings += "avatarStyle=" + props.avatar?.properties.avatarStyle;
    settings += "&topType=" + props.avatar?.properties.topType;
    settings += "&accessoriesType=" + props.avatar?.properties.accessoriesType;
    settings += "&hatColor=" + props.avatar?.properties.hatColor;
    settings += "&hairColor=" + props.avatar?.properties.hairColor;
    settings += "&facialHairType=" + props.avatar?.properties.facialHairType;
    settings += "&facialHairColor=" + props.avatar?.properties.facialHairColor;
    settings += "&clotheType=" + props.avatar?.properties.clotheType;
    settings += "&eyeType=" + props.avatar?.properties.eyeType;
    settings += "&eyebrowType=" + props.avatar?.properties.eyebrowType;
    settings += "&mouthType=" + props.avatar?.properties.mouthType;
    settings += "&skinColor=" + props.avatar?.properties.skinColor;
    settings += "&clotheColor=" + props.avatar?.properties.clotheColor;
    return settings;
}
