import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { CardBio } from "../../../ov-common/models/card";
import { QueryKeys } from "../../../query-keys";
import { addCard, getCards, removeCard } from "../../../services/users.sevice";

export const useAddCard = () => {
    return useMutation({
        mutationFn: (props: {newCard: CardBio, formData: FormData | null, file: File | null | undefined}) => addCard(props.newCard, props.formData, props.file),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.Card ], [ data ]);
        },
    });
};

export const useRemoveCard = () => {
    return useMutation({
        mutationFn: (oldCard: CardBio) => removeCard(oldCard),
        onSuccess: () => {
            queryClient.setQueryData([ QueryKeys.Card ], null);
        },
    });
};

export const useGetCard = (userId: string) => {
    return useQuery({
        queryKey: [ QueryKeys.Card ],
        queryFn: () => getCards(userId),
    });
};
