
import { Box, BoxProps as ChakraBoxProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
  
  type Author = 'me' | 'you';
  
export type MessageProps = {
    children?: ReactNode;
    /** Displays the message received time. */
    bottomFooter?: JSX.Element;
    /** Displays the sender of the message. */
    from?: string;
    /** Changes the styling on chat bubble background and border bottom radius */
    author?: Author;
    /** Auto floats the bubble to the right or left depending on the author */
    autoFloat?: boolean;
  } & ChakraBoxProps;
  
/**
   * Shows a container appearing as a message "chat bubble" with text content.
   *
   * @see Docs https://bit.cloud/caspeco/casper-ui-library/components/message
   */
export function Message({
    children,
    bottomFooter: bottom,
    from,
    author,
    autoFloat,
    key,
    ...props
}: MessageProps) {
    const youOrDefault = author == null || author === 'you';
    const alignSelfValue = youOrDefault ? 'flex-start' : 'flex-end';
  
    return (
        <Box {...props} alignSelf={autoFloat ? alignSelfValue : undefined}>
            <Box
                bg={
                    !youOrDefault
                        ? "#3E51B5"
                        : "#E9E8E7"
                }
                px={"1rem"}
                py={"0.5rem"}
                borderTopRightRadius={"10px"}
                borderTopLeftRadius={"10px"}
                borderBottomRightRadius={
                    !youOrDefault ? "10px" : undefined
                }
                borderBottomLeftRadius={
                    !youOrDefault ? undefined : "10px"
                }
            >
                <Text
                    fontSize={"16px"}
                    color={
                        !youOrDefault
                            ? "white"
                            : "black"
                    }
                >
                    {children}
                </Text>
                {from && (
                    <Text
                        fontSize={"16px"}
                        color={
                            !youOrDefault
                                ? "black"
                                : "white"
                        }
                    >
                        {from}
                    </Text>
                )}
            </Box>
            {bottom && (
                <Box ml={"0.5rem"} style={{ fontSize: "0.75rem" }}>
                    {bottom}
                </Box>
            )}
        </Box>
    );
}
  