import { enUS, sv } from "date-fns/locale";
import React, { useEffect } from "react";
import { enTranslations } from "./en";
import { svTranslations } from "./sv";

export const Lang = () => {
    const [ lang, setLang ] = React.useState(enUS);
    const [ translations, setTranslations ] = React.useState<{ [key: string]: string }>(enTranslations);

    useEffect(() => {
        const langCode = localStorage.getItem('lang-code');
        if(langCode === 'sv-SE' || langCode === 'sv') {
            setLang(sv);
            setTranslations(svTranslations);
        }else{
            setLang(enUS);
            setTranslations(enTranslations);
        }
    }, []);

    const setLanguage = (langCode: string) => {
        localStorage.setItem('lang-code', langCode);
        if(langCode === 'sv-SE' || langCode === 'sv') {
            setTranslations(svTranslations);
            setLang(sv);
        }else{
            setTranslations(enTranslations);
            setLang(enUS);
        }
    };

    const t = (key: string) => {
        return translations[key] || key;
    };

    return { lang, setLanguage, t };
};