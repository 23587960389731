import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../query-keys';
import { getConversation } from '../../../services/letters.service';

// A custom hook for getting a conversation using React Query
export const useGetConversation = (from?: string, to?: string) => {
    return useQuery({
        queryKey: [ QueryKeys.Conversation ],
        queryFn: () => from != null && to != null ? getConversation(from, to) : null,
    });
};