import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Spinner,
    useToast,
    VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContainerFullWidthHeightComponent } from '../../components/rows/container-full-width-height.component';
import { Lang } from '../../lang/lang'; // Import Lang for translations
import { GoBackButton } from '../letters/send-letters.screen';
import { useUpdatePasswordAndUsername } from './hooks/user-query';

export const ProfileScreen = () => {
    const { t } = Lang(); // Access the translation function
    const location = useLocation();
    const [ clickedGoBack, setClickedGoBack ] = React.useState<boolean>(false);
    const toast = useToast();
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');

    const mutation = useUpdatePasswordAndUsername();

    const handleSubmit = async(e: React.FormEvent) => {
        e.preventDefault();

        try {
            // Call mutation to update user information
            await mutation.mutateAsync({ anvandarnamn: username, losenord: password });

            // Show success message
            toast({
                title: t('profileScreenUpdateSuccessTitle'),
                description: t('profileScreenUpdateSuccessDescription'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            // Show error message
            toast({
                title: t('profileScreenUpdateErrorTitle'),
                description: t('profileScreenUpdateErrorDescription'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    return (
        <>
            {GoBackButton(clickedGoBack, location, setClickedGoBack, t)}
            <ContainerFullWidthHeightComponent>
                <Box w="100%" maxW="500px" mx="auto" mt="10" pl={5} pr={5}>
                    <Heading as="h1" mb="6" textAlign="center" fontSize={"1.5rem"}>
                        {t('profileScreenHeading')}
                    </Heading>
                    <form onSubmit={handleSubmit}>
                        <VStack spacing="6">
                            <FormControl id="username" isRequired>
                                <FormLabel fontSize={"1rem"}>{t('profileScreenUsernameLabel')}</FormLabel>
                                <Input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder={t('profileScreenUsernamePlaceholder')}
                                />
                            </FormControl>

                            <FormControl id="password" isRequired>
                                <FormLabel fontSize={"1rem"}>{t('profileScreenPasswordLabel')}</FormLabel>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={t('profileScreenPasswordPlaceholder')}
                                />
                            </FormControl>

                            <Button
                                type="submit"
                                colorScheme="teal"
                                isLoading={mutation.isPending}
                                loadingText={t('profileScreenUpdating')}
                                fontSize={"1rem"}
                            >
                                {t('profileScreenUpdateButton')}
                            </Button>
                        </VStack>
                    </form>

                    {mutation.isPending && <Spinner mt="4" />}
                </Box>
            </ContainerFullWidthHeightComponent>
        </>
    );
};
