import { sendPostWithToken } from "../http-utilities/http-utilities";
import { CardBio } from "../ov-common/models/card";
import { User, UserListItem } from "../ov-common/models/user";
import { Avatar } from "../screens/avatar/avatar.component";
import env from "./../env.json";
import { updatePersonalInformationProps } from "./../screens/user-actions/hooks/user-query";

// Write a function that takes _id and notInIds (array of strings) as parameters and use sendPostWithToken to send a post request to /anvandare/utforska and uses token from localStorage. return Promise of type UserListItem.
export const explore = (notInIds: string[]): Promise<UserListItem[]> => {
    return sendPostWithToken('anvandare/utforska', { notInIds });
};

export const getSteve = (): Promise<User> => {
    return sendPostWithToken('anvandare/get-steve', {});
};

export const updatePersonalInformation = (user: updatePersonalInformationProps): Promise<User> => {
    return sendPostWithToken('anvandare/uppdateraKontouppgifter', user);
};

export const engageWithUser = (userId: string): Promise<void> => {
    return sendPostWithToken('anvandare/engageWithUser', { userId });
};

// write a function that takes a Avatar as parameter and sends a request to anvandare/updateAvatar with the avatar as body. return a status code.
export const updateAvatar = (avatar: Avatar): Promise<Avatar> => {
    return sendPostWithToken('anvandare/updateAvatar', avatar);
};

export const updateLangIndex = (langIndex: string): Promise<string> => {
    return sendPostWithToken('anvandare/langIndex', { langIndex });
};

export const updateBirthday = (birthday: string): Promise<string> => {
    return sendPostWithToken('anvandare/birthday', { birthday: birthday });
};

// endpoint: cards/laggTillEttCard takes in class Card as parameter and send a request to cards/laggTillEttCard with the card as body. return a status code. Use sendPostWithToken
export const addCardHttp = (card: CardBio): Promise<CardBio> => {
    return sendPostWithToken('cards/laggTillEttCard', card);
};

export const updateGoal = (goal: string, userId: string): Promise<User> => {
    return sendPostWithToken('anvandare/update-goal', { goal, userId });
};

// Add a remove cards/taBortCard and update cards/editCard 
export const removeCard = (card: CardBio): Promise<CardBio> => {
    return sendPostWithToken('cards/taBortCard', { id: card.id });
};

export const removeImageCard = (cardId: string): Promise<CardBio> => {
    return sendPostWithToken('cards/remove-card-image', { cardId });
};

export const updateCard = (card: CardBio): Promise<CardBio> => {
    return sendPostWithToken('cards/editCard', card);
};

export const getCards = (_id: string): Promise<CardBio[]> => {
    return sendPostWithToken('cards/hamtaAnvandarensCards', { id: _id });
};

export const addCard = async(card: CardBio, formData: FormData | null, file: File | null | undefined): Promise<CardBio | undefined> => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token || !userId) {
        return;
    }
    const newCard = await addCardHttp(card);

    if (formData && file) {
        const upload = await fetch(
            env.backend + 'cards/upload?id=' + newCard.id +
            '&filename=' + file.name,
            {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'bearer ' + token,
                }),
                body: formData,
            }
        );

        const uploadData = await upload.json() as any;

        newCard.url = uploadData.url;
    }

    return newCard;
};