import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { buttonStyle, inputStyle, margins } from '../../colors/global-styles';
import { ColoredBoxComponent } from '../../components/colored-box.component';
import { OneRowComponent } from '../../components/rows/one-row.component';
import { Lang } from '../../lang/lang'; // Import Lang for translations
import { UserListItem } from '../../ov-common/models/user';
import { mapUserToUserListItem } from '../../ov-common/utilities/map.utilities';
import { getSteve } from '../../services/users.sevice';
import { AvatarMapperComponent } from '../avatar/avatar.component';
import { useLogin } from './hook/auth-query';

export const LoginComponent = () => {
    const { t, setLanguage } = Lang(); // Access translation function and setLanguage
    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ steve, setSteve ] = useState<UserListItem | null>(null);
    const { mutate: login } = useLogin();

    const handleLogin = useCallback(() => {
        const user = username;
        const pw = password;
        if (user.length >= 3 && pw.length >= 3) {
            login({ anvandarnamn: user, losenord: pw });
        }
    }, [ username, password ]);

    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }, [ handleLogin ]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [ handleLogin, handleKeyPress ]);

    useEffect(() => {
        getSteve().then((st) => {
            if (st) {
                setSteve(mapUserToUserListItem(st));
            }
        });
    }, []);

    return (
        <div className='App-header' style={{ overflow: 'hidden', background: "white", textAlign: "center" }}>
            <OneRowComponent>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <img src="/SE.png" alt="No new messages" onClick={() => setLanguage('sv-SE')}  style={{ width: 50, cursor: 'pointer' }} />
                    <img src="/US.png" alt="No new messages" onClick={() => setLanguage('en-US')}  style={{ width: 50, cursor: 'pointer' }} />
                </div>
            </OneRowComponent>
            {steve && <div>
                <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0, display: "flex", justifyContent: "center" }}>
                        <AvatarMapperComponent
                            avatar={steve.avatar}
                            width={100}
                            height={100}
                        />
                    </div>
                </Container>
            </div>}
            <OneRowComponent>
                <div style={margins.margin}>
                    <h1 className='snellbt' style={margins.margin}>{t("loginTagline")}</h1>
                </div>
            </OneRowComponent>
            <OneRowComponent>
                <form>
                    <ColoredBoxComponent>
                        <OneRowComponent>
                            <label htmlFor="username">{t("loginUsernameLabel")}</label>
                        </OneRowComponent>
                        <OneRowComponent>
                            <input type="text" name="username" id="username" style={inputStyle.input}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                            />
                        </OneRowComponent>
                        <OneRowComponent>
                            <label htmlFor="password">{t("loginPasswordLabel")}</label>
                        </OneRowComponent>
                        <OneRowComponent>
                            <input type="password" name="password" id="password" style={inputStyle.input}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            />
                        </OneRowComponent>
                    </ColoredBoxComponent>
                    <OneRowComponent>
                        <div style={margins.margin}></div>
                    </OneRowComponent>
                    <OneRowComponent>
                        <div onClick={() => {
                            handleLogin();
                        }} className='call-to-action' style={buttonStyle.blueButton}>{t("loginButton")}</div>
                    </OneRowComponent>
                    <OneRowComponent>
                        <a href="/signup" style={buttonStyle.defaultButton} className='signUpDarkMode'>{t("loginSignupLink")}</a>
                    </OneRowComponent>
                </form>
            </OneRowComponent>
        </div>
    );
};
