import { Letter } from "../letter";

// order letters to oldest to newest
export const sortLettersByDate = (letters: Letter[]): Letter[] => {
    return letters.sort((a: Letter, b: Letter) => {
        return new Date(a.datum).getTime() - new Date(b.datum).getTime();
    });
};

export function calculateAge(birthday: Date): number {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}