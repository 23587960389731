import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { LoginComponent } from './screens/auth/login.screen';
import { HomeScreenComponent } from './screens/home-screen.screen';
import { EditAvatarComponent } from './screens/user-actions/edit-avatar.screen';
import { WriteBioComponent } from './screens/user-actions/write-bio.screen';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthLogicComponent } from './screens/auth/auth-logic.screen';
import { SignupComponent } from './screens/auth/signup.screen';
import { SendLettersComponent } from './screens/letters/send-letters.screen';

import { ChakraProvider } from '@chakra-ui/react';

// use?
//import 'semantic-ui-css/semantic.min.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GradeScreen } from './screens/analytics/grade-screen';
import { SettingsScreen } from './screens/settings/settings.screen';
import { AgeScreen } from './screens/user-actions/age.screen';
import { GoalScreen } from './screens/user-actions/goal.screen';
import { LanguageScreen } from './screens/user-actions/language-select.screen';
import { ProfileScreen } from './screens/user-actions/profile-screen';

const router = createBrowserRouter([
    {
        path: '/', element:
      <AuthLogicComponent noProtectedSites={true} >
          <LoginComponent />
      </AuthLogicComponent>,
    },
    {
        path: '/signup', element:
      <AuthLogicComponent noProtectedSites={true}>
          <SignupComponent />
      </AuthLogicComponent>,
    },
    {
        path: '/write-bio', element:
      <AuthLogicComponent>
          <WriteBioComponent />
      </AuthLogicComponent>,
    },
    {
        path: '/edit-avatar', element:
      <AuthLogicComponent>
          <EditAvatarComponent />
      </AuthLogicComponent>,
    },
    {
        path: '/inbox', element:
      <AuthLogicComponent>
          <HomeScreenComponent />
      </AuthLogicComponent>,
    },
    {
        path: '/send-letter', element:
      <AuthLogicComponent>
          <SendLettersComponent />
      </AuthLogicComponent>,
    },
    {
        path: '/settings', element:
      <AuthLogicComponent >
          <SettingsScreen />
      </AuthLogicComponent>,
    },
    {
        path: '/profile', element:
      <AuthLogicComponent >
          <ProfileScreen />
      </AuthLogicComponent>,
    },
    {
        path: '/language', element: (
            <AuthLogicComponent >
                <LanguageScreen />
            </AuthLogicComponent >
        ),
    },
    {
        path: '/goals', element:
      <AuthLogicComponent>
          <GoalScreen />
      </AuthLogicComponent>,
    },
    {
        path: '/age', element: (
            <AuthLogicComponent >
                <AgeScreen />
            </AuthLogicComponent >
        ),
    },
    {
        path: '/grade', element: (
            <AuthLogicComponent >
                <GradeScreen />
            </AuthLogicComponent >
        ),
    },
]);

export const darkMode = false;

export const queryClient = new QueryClient();

function App() {
    localStorage.setItem("chakra-ui-color-mode", "true");
    const g = document.getElementById("theme") as HTMLLinkElement;
    if (darkMode && g != null) {
        g.href = "/dark.css";
    }
    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider>
                <div className='App'>
                    <ToastContainer />
                    <RouterProvider router={router} />
                </div>
            </ChakraProvider>
        </QueryClientProvider>
    );
}

export default App;
