import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import { User } from '../../../ov-common/models/user';
import { QueryKeys } from '../../../query-keys';
import { login, register, verifyToken } from '../../../services/auth.service';

// A custom hook for verifying the token using React Query
export const useVerifyToken = () => {
    return useQuery({
        queryKey: [ QueryKeys.User ],
        queryFn: () => verifyToken(),
    });
};

interface registerProps {
    anvandarnamn: string;
    losenord: string;
    fodelsedatum: Date;
    langIndex: string;
}

export const useRegister = () => {
    return useMutation({
        mutationFn: (props: registerProps) => register(props.anvandarnamn, props.losenord, props.fodelsedatum, props.langIndex),
        onSuccess: (data) => {
            data.isOnSignup = true;
            queryClient.setQueryData([ QueryKeys.User ], data);
            localStorage.setItem('token', data.token);
            localStorage.setItem('userId', data._id);
        },
    });
};

export const useLogin = () => {
    return useMutation({
        mutationFn: (props: { anvandarnamn: string, losenord: string }) => login(props.anvandarnamn, props.losenord),
        onSuccess: (data) => {
            queryClient.setQueryData([ QueryKeys.User ], data);
            localStorage.setItem('token', data.token);
            localStorage.setItem('userId', data._id);
        },
    });
};

export const getUser = () => {
    const cachedData = queryClient.getQueryData([ QueryKeys.User ]) as User;
    return cachedData || null;
};

export const useLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    window.location.href = '/';
};